import {
  createAsyncThunk,
  createSlice,
  Middleware,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit";
import { buildQueries } from "@testing-library/react";
import { FirebaseApp, FirebaseError } from "firebase/app";
import { OAuthCredential, User, UserCredential } from "firebase/auth";
import { firebaseAuthService } from "../firebase";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { UserImpl } from "@firebase/auth/internal";
import { RootState } from "../store";
import {
  getUserStats as httpGetUserStats,
  getUser as httpGetUser,
} from "../services/http-api-data-service";
import { AhtloyUserStats, AthloyUser, UserExercise } from "../models/athloy-user";
import { BlurLinear } from "@mui/icons-material";
import { logger } from "../logger";

//import { history, fetchWrapper } from 'helpers';

// create slice

// Define a type for the slice state
interface AuthState {
  user: AuthUser | null;
  error: String | null;
}

interface AuthUser {
  userName: String;
  token: String;
  userId: String;
  sex: String | null;
  avatarStr: String | null;
  hrZones: number[] | null;
  exercises: UserExercise[] | null;
}

const name = "auth";
// const initialState: AuthState = {
//   user: null,
//   error: null,
// };

function createInitialState(): AuthState {
  let localUser = localStorage.getItem("user");
  let user = localUser != null ? (JSON.parse(localUser) as AuthUser) : null;
  return {
    // initialize state from local storage to enable user to stay logged in
    user: user,
    error: null,
  };
}

export const exampleMiddleware: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  RootState
> = (storeApi) => (next) => (action) => {
  const state = storeApi.getState(); // correctly typed as RootState
};

//const reducers = createReducers();
const extraActions = createExtraActions();
const initialState = createInitialState();

//const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      firebaseAuthService.logout();
      //navigate('/');
    },
  },
  extraReducers(builder) {
    builder.addCase(
      extraActions.logInWithEmailAndPassword.pending,
      (state: AuthState) => {
        state.error = null;
      }
    );
    builder.addCase(
      extraActions.logInWithGoogle.pending,
      (state: AuthState) => {
        state.error = null;
      }
    );
    builder.addCase(
      extraActions.loginWithApple.pending,
      (state: AuthState) => {
        state.error = null;
      }
    );

    builder.addCase(extraActions.getUser.fulfilled, handleUserDetails);

    // builder.addCase(extraActions.getUserStats.fulfilled, handleUserStats);

    builder.addCase(
      extraActions.logInWithEmailAndPassword.fulfilled,
      handleLoginComplete
    );

    builder.addCase(
      extraActions.logInWithGoogle.fulfilled,
      (state: AuthState, action: { payload: any }) =>
        handleLoginComplete(state, action)
    );

    builder.addCase(
      extraActions.loginWithApple.fulfilled,
      (state: AuthState, action: { payload: any }) =>
        handleOAuthLoginComplete(state, action)
    );

    builder.addCase(
      extraActions.logInWithEmailAndPassword.rejected,
      handleLoginRejected
    );

    builder.addCase(extraActions.logInWithGoogle.rejected, handleLoginRejected);
    builder.addCase(extraActions.loginWithApple.rejected, handleLoginRejected);

    builder.addCase("TOKEN_UPDATE", (state, action: any) => {
      logger.log(`Id token updated...${action.data}`);
      if (action.data) {
        var authUser: AuthUser = {
          ...state.user!,
          token: action.data.accessToken,
        };
        localStorage.setItem("user", JSON.stringify(authUser));
        state.user = authUser;
      }
    });
  },
});

function handleLoginRejected(state: AuthState, action: any) {
  if (action.payload && action.payload instanceof FirebaseError) {
    state.error = firebaseAuthService.mapErrorToMessage(
      action.payload as FirebaseError
    );
  } else {
    state.error = action.error;
  }
}

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

type OAuthPayload = {
  credential: OAuthCredential; 
  user:any
}

function handleOAuthLoginComplete(state: AuthState, action: { payload: any }) {       
  const firebaseUser = action.payload as OAuthPayload;
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  var authUser: AuthUser = {
    userName: firebaseUser.user.email,
    token: firebaseUser.user.accessToken,
    userId: firebaseUser.user.uid,
    sex: null,
    avatarStr: null,
    hrZones: null,
    exercises: null
  };
  localStorage.setItem("user", JSON.stringify(authUser));

  state.user = authUser;
}

function handleLoginComplete(state: AuthState, action: { payload: any }) {     
  const firebaseUser = action.payload;
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  var authUser: AuthUser = {
    userName: firebaseUser.displayName,
    token: firebaseUser.accessToken,
    userId: firebaseUser.uid,
    sex: null,
    avatarStr: null,
    hrZones: null,
    exercises: null
  };
  localStorage.setItem("user", JSON.stringify(authUser));

  state.user = authUser;
}

function handleUserDetails(state: AuthState, action: { payload: any }) {
  const user = action.payload as AthloyUser;
  if (user) {
    logger.log(`User details: ${user}`);

    var authUser: AuthUser = {
      ...state.user!,
      sex: user.athleteStats.basicInfo.sex,
      avatarStr: user.avatarString,
      hrZones: user.athleteStats.basicInfo.hrZones,
      exercises: user.userExercises
    };
    localStorage.setItem("user", JSON.stringify(authUser));

    state.user = authUser;
  }
}

// function handleUserStats(state: any, action: { payload: any }) {
//   logger.log(`User stats: ${action.payload}`);

//   const userStats = action.payload as AhtloyUserStats;

//     var authUser: AuthUser = {
//       ...state.user!,
//       sex: user.athleteStats.basicInfo.sex,
//       avatarStr: user.avatarString,
//     };
//     localStorage.setItem("userStats", JSON.stringify(authUser));

//     state.user = authUser;
//   }
// }

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

  return {
    login: login(),
    logInWithEmailAndPassword: logInWithEmailAndPassword(),
    logInWithGoogle: logInWithGoogle(),
    loginWithApple: logInWithApple(),
    getUserStats: getUserStats(),
    getUser: getUser(),
  };

  function getUserStats() {
    return createAsyncThunk(
      `${name}/getUserStats`,
      async (_: {}) => await httpGetUserStats()
    );
  }

  function getUser() {
    return createAsyncThunk(
      `${name}/getUser`,
      async (_: {}) => await httpGetUser()
    );
  }

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async (_: { username: string; password: string }) =>
        await fetchWrapper.post(`${baseUrl}/authenticate`, {
          username: _.username,
          password: _.password,
        })
    );
  }

  function logInWithEmailAndPassword() {
    return createAsyncThunk<
      // Return type of the payload creator
      any,
      // First argument to the payload creator
      { username: string; password: string },
      // Types for ThunkAPI
      {
        // extra: {
        //   jwt: string
        // },
        rejectValue: FirebaseError;
      }
    >(
      `${name}/logInWithEmailAndPassword`,
      //async (_: { username: string; password: string }) =>
      async (_, thunkApi) => {
        try {
          await firebaseAuthService.logInWithEmailAndPassword(
            _.username,
            _.password
          );
        } catch (error) {
          return thunkApi.rejectWithValue(error as FirebaseError);
        }
      }
    );
  }
}

function logInWithGoogle() {
  return createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    {},
    // Types for ThunkAPI
    {      
      rejectValue: FirebaseError;
    }
  >(
    `${name}/logInWithGoogle`,    
    async (_, thunkApi) => {
      try {
        return await firebaseAuthService.signInWithGoogle();
      } catch (error) {
        return thunkApi.rejectWithValue(error as FirebaseError);
      }
    }
  );
}

function logInWithApple() {
  return createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    {},
    // Types for ThunkAPI
    {   
      rejectValue: FirebaseError;
    }
  >(
    `${name}/logInWithApple`,    
    async (_, thunkApi) => {
      try {
        return await firebaseAuthService.signInWithApple();
      } catch (error) {
        return thunkApi.rejectWithValue(error as FirebaseError);
      }
    }
  );
}

