// export const DARK_MODE = "DARK_MODE";

import { createSlice } from "@reduxjs/toolkit";

// // initial state
// const initialState = {
//   // checking mode from localstorage if falsey (e.g. 0, null, undefined, etc.) it will be false, otherwise true
//   isdarkMode: !!JSON.parse(localStorage.getItem("darkmode")),
// };
interface SettingsState {
  settings: Settings;
  error: String | null;
}

interface Settings {
  darkMode: boolean;
  distanceUnits: string;
  elevationUnits: string;
  weightUnits: string;
}

const initialState = (): SettingsState => {
  let localSettings = localStorage.getItem("settings");
  let settings = localSettings ? (JSON.parse(localSettings) as Settings) : null;
  
  return  {
    settings: settings ?? {
      darkMode: false,
      distanceUnits: "mi",
      elevationUnits: "ft",
      weightUnits: "lbs",
    },
    error: null,
  };
};


const name = "settings";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setDarkMode: (state, action) => {
      state = {
        ...state,
        settings: { ...state.settings, darkMode: action.payload },
      };
      localStorage.setItem("settings", JSON.stringify(state.settings));
      return state;
    },
    setDistanceUnit: (state, action) => {
      state = {
        ...state,
        settings: { ...state.settings, distanceUnits: action.payload },
      };
      localStorage.setItem("settings", JSON.stringify(state.settings));
      return state;
    },
    setElevationUnit: (state, action) => {
      state = {
        ...state,
        settings: { ...state.settings, elevationUnits: action.payload },
      };
      localStorage.setItem("settings", JSON.stringify(state.settings));
      return state;
    },
    setWeightUnit: (state, action) => {
      state = {
        ...state,
        settings: { ...state.settings, weightUnits: action.payload },
      };
      localStorage.setItem("settings", JSON.stringify(state.settings));
      return state;
    },
  },
  extraReducers: {},
});

export const settingsReducer = slice.reducer;
export const settingsActions = { ...slice.actions };
