import React, { useState } from "react";
import "./App.css";
import FirebaseLogin from "./components/firebase-login/firebase-login";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { AthloyRed } from "./theme";
import Canvas from "./components/common/canvas";
import FallingPixels from "./components/common/falling-pixels";

function Landing() {
  const ref = React.useRef<HTMLDivElement>(null);

  const footer = (
    <Container>
      <Grid container spacing={0} alignItems="center">
        <Grid
          item
          xs={12}
          alignContent="center"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography align="center">
            <Link
              className="btn-social"
              href="https://twitter.com/AthloyApp"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
            </Link>
          </Typography>

          <Typography align="center">
            <Link
              className="btn-social"
              href="https://discord.gg/yDTgQR3XVS"
              target="_blank"
            >
              <FontAwesomeIcon icon={faDiscord}></FontAwesomeIcon>
            </Link>
          </Typography>

          <Typography align="center">
            <Link
              className="btn-social"
              href="mailto:support@athloy.com"
              target="_blank"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className="copy">
            Copyright &copy; Athloy {new Date().getFullYear()}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
  const gridItem = {
    margin: "8px",
    border: "1px solid red",
  };

  // type PixelBlock = {
  //   rectX: number;
  //   rectY: number;
  //   colIndex: number;
  //   createdTimeMs: number;
  // };

  // let activePixelCols = new Array<PixelBlock>();

  // const MAX_CONCURRENT = 10;
  // const velocity = 20;  
  // let cols = 0;
  // let colTotals = Array.from({ length: cols }, (_, i) => 0);  

  // const draw = (ctx: RenderingContext, frameCount: number) => {
  //   const context = ctx as CanvasRenderingContext2D;

  //   const size = Math.ceil(ctx.canvas.width / cols);
  //   const maxHeight = Math.round(ctx.canvas.height / size);

  //   // Reset if all filled
  //   if (colTotals.filter((i) => i >= maxHeight).length == colTotals.length) {
  //     context.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  //     colTotals = Array.from({ length: cols }, (_, i) => 0);      
  //   }

  //   context.fillStyle = AthloyRed[500];
  //   if (activePixelCols.length < MAX_CONCURRENT) {
  //     const createDate = Date.now();
  //     if (
  //       activePixelCols.filter((e) => createDate - e.createdTimeMs < 50)
  //         .length == 0
  //     ) {
  //       // Get random next column index that is not already at max height
  //       let colIndex = getRandomIndex(cols);
  //       while (colTotals[colIndex] >= maxHeight) {
  //         colIndex = getRandomIndex(cols);
  //       }
        
  //       activePixelCols.push({
  //         rectX: colIndex * size,
  //         rectY: -size,
  //         colIndex: colIndex,
  //         createdTimeMs: createDate,
  //       });
  //     }
  //   }

  //   activePixelCols.forEach((element) => {
  //     drawPixel(size, ctx, context, element);
  //   });
  // };

  // const [canvasSize, setCanvasSize] = useState({ height: 0, width: 0 });

  // React.useEffect(() => {
  //   setCanvasSize({
  //     width: document.body.clientWidth,
  //     height: document.body.clientHeight,
  //   });
  // }, []);

  return (
    <div className="App">
      {/* <Canvas
        style={{ position: "absolute" }}
        draw={draw}
        canvasProperties={{
          width: canvasSize.width,
          height: canvasSize.height * 0.85,
        }}
      ></Canvas> */}
      <FallingPixels></FallingPixels>
      <Box className="main">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100%" }}
        >
          <Box
            sx={{
              height: "100%",
              display: "inline-block",
              p: 1,
              mx: 1,
              textAlign: "center",
            }}
          >
            <FirebaseLogin></FirebaseLogin>
          </Box>
        </Grid>
      </Box>
      <Box className="footer" sx={{backgroundColor: AthloyRed[500]}}>{footer}</Box>
    </div>
  );

  // function drawPixel(
  //   size: number,
  //   ctx: RenderingContext,
  //   context: CanvasRenderingContext2D,
  //   pixelData: PixelBlock
  // ) {
  //   if (
  //     pixelData.rectY + size >=
  //     ctx.canvas.height - size * colTotals[pixelData.colIndex]
  //   ) {
  //     pixelData.rectY = -size;
  //     colTotals[pixelData.colIndex]++;

  //     // This column is done, remove from active array
  //     activePixelCols = activePixelCols.filter((p) => p != pixelData);

  //     // colIndex = getRandomIndex(cols - 1);
  //     // pixelData.rectX = colIndex * size;
  //   } else {
  //     context.clearRect(pixelData.rectX, pixelData.rectY, size, size);

  //     const yInc =
  //       pixelData.rectY + size + velocity <
  //       ctx.canvas.height - size * colTotals[pixelData.colIndex]
  //         ? velocity
  //         : ctx.canvas.height -
  //           size * colTotals[pixelData.colIndex] -
  //           (pixelData.rectY + size);

  //     pixelData.rectY += yInc;
  //   }
  //   context.fillRect(pixelData.rectX, pixelData.rectY, size, size);
  // }
}

export default Landing;

// function getRandomIndex(arg0: number): number {
//   return Math.floor(Math.random() * arg0);
// }
