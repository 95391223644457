import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "../helpers/history";
import { RootState } from "../store";
import { logger } from "../logger";

export { PrivateRoute };

type Props = {
  children: JSX.Element;
};

function PrivateRoute({ children }: Props) {
  const { user: authUser } = useSelector((x: RootState) => x.auth);

  if (!authUser) {
    // not logged in so redirect to login page with the return url
    logger.log('Not logged in, redirecting...');
    return <Navigate to="/" state={{ from: history.location }} />;
  }

  // authorized so return child components
  return children;
}
