// To parse this data:
//
//   import { Convert } from "./file";
//
//   const athloyFeedItem = Convert.toAthloyFeedItem(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface AthloyFeedItem {
  id: string;
  itemDate: Date;
  workout: Workout | null;
  activity: Activity | null;
}

export interface Activity {
  activityId: string;
  otherIds: string[] | null;
  userId: string;
  title: string;
  source: Source;
  activityType: ActivityType;
  activityDate: Date;
  durationInSeconds: number;
  distanceInMeters: number;
  calories: number | null;
  elevationGainInMeters: number;
  avgHeartRate: number | null;
  avgWatts: number | null;
  isProcessed: boolean;
  coinCalculation: ActivityCoinCalculation | null;
  fitFileUrl: null | string;
  dataStreams: null;
  bestSplits: BestSplit[] | null;
  internalId: string;
}

export enum ActivityType {
  Cycle = "Cycle",
  Hike = "Hike",
  Run = "Run",
}

export interface BestSplit {
  distance: number;
  distanceType: DistanceType;
  durationInSeconds: number;
  activityId: null;
}

export enum DistanceType {
  K = "K",
}

export interface ActivityCoinCalculation {
  total: number;
  multipliers: Multipliers;
  breakdown: PurpleBreakdown;
}

export interface PurpleBreakdown {
  elevation: number;
  distance: number;
}

export interface Multipliers {
  streak: number;
  sameday: number;
  multisport: number;
}

export enum Source {
  Strava = "Strava",
  Wahoo = "Wahoo",
}

export interface Workout {
  id: number;
  id_str: string;
  userId: string;
  startTime: Date;
  endTime: Date;
  exercises: Exercise[];
  isTimed: boolean;
  isProcessed: boolean | null;
  coinCalculation: WorkoutCoinCalculation | null;
  internalId: string;
}

export interface WorkoutCoinCalculation {
  total: number;
  multipliers: Multipliers;
  breakdown: FluffyBreakdown;
}

export interface FluffyBreakdown {
  workout: number;
}

export interface Exercise {
  exerciseCode: string;
  isUser: boolean;
  exerciseName: string;
  startTime: Date | null;
  reps: number;
  weight: number;
  unit: Unit;
  isProcessed: null;
  coins: null;
}

export enum Unit {
  Kgs = "Kgs",
  Lbs = "Lbs",
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toAthloyFeedItem(json: string): AthloyFeedItem[] {
    return cast(JSON.parse(json), a(r("AthloyFeedItem")));
  }

  public static athloyFeedItemToJson(value: AthloyFeedItem[]): string {
    return JSON.stringify(uncast(value, a(r("AthloyFeedItem"))), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ""): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    );
  }
  throw Error(
    `Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
  );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  AthloyFeedItem: o(
    [
      { json: "id", js: "id", typ: "" },
      { json: "itemDate", js: "itemDate", typ: Date },
      { json: "workout", js: "workout", typ: u(r("Workout"), null) },
      { json: "activity", js: "activity", typ: u(r("Activity"), null) },
    ],
    false
  ),
  Activity: o(
    [
      { json: "activityId", js: "activityId", typ: "" },
      { json: "otherIds", js: "otherIds", typ: u(a(""), null) },
      { json: "userId", js: "userId", typ: r("UserID") },
      { json: "title", js: "title", typ: "" },
      { json: "source", js: "source", typ: r("Source") },
      { json: "activityType", js: "activityType", typ: r("ActivityType") },
      { json: "activityDate", js: "activityDate", typ: Date },
      { json: "durationInSeconds", js: "durationInSeconds", typ: 0 },
      { json: "distanceInMeters", js: "distanceInMeters", typ: 3.14 },
      { json: "calories", js: "calories", typ: u(0, null) },
      { json: "elevationGainInMeters", js: "elevationGainInMeters", typ: 3.14 },
      { json: "avgHeartRate", js: "avgHeartRate", typ: u(0, null) },
      { json: "avgWatts", js: "avgWatts", typ: u(0, null) },
      { json: "isProcessed", js: "isProcessed", typ: true },
      {
        json: "coinCalculation",
        js: "coinCalculation",
        typ: u(r("ActivityCoinCalculation"), null),
      },
      { json: "fitFileUrl", js: "fitFileUrl", typ: u(null, "") },
      { json: "dataStreams", js: "dataStreams", typ: null },
      { json: "bestSplits", js: "bestSplits", typ: u(a(r("BestSplit")), null) },
      { json: "internalId", js: "internalId", typ: "" },
    ],
    false
  ),
  BestSplit: o(
    [
      { json: "distance", js: "distance", typ: 0 },
      { json: "distanceType", js: "distanceType", typ: r("DistanceType") },
      { json: "durationInSeconds", js: "durationInSeconds", typ: 0 },
      { json: "activityId", js: "activityId", typ: null },
    ],
    false
  ),
  ActivityCoinCalculation: o(
    [
      { json: "total", js: "total", typ: 3.14 },
      { json: "multipliers", js: "multipliers", typ: r("Multipliers") },
      { json: "breakdown", js: "breakdown", typ: r("PurpleBreakdown") },
    ],
    false
  ),
  PurpleBreakdown: o(
    [
      { json: "elevation", js: "elevation", typ: 3.14 },
      { json: "distance", js: "distance", typ: 3.14 },
    ],
    false
  ),
  Multipliers: o(
    [
      { json: "streak", js: "streak", typ: 3.14 },
      { json: "sameday", js: "sameday", typ: 0 },
      { json: "multisport", js: "multisport", typ: 0 },
    ],
    false
  ),
  Workout: o(
    [
      { json: "id", js: "id", typ: 3.14 },
      { json: "id_str", js: "id_str", typ: "" },
      { json: "userId", js: "userId", typ: r("UserID") },
      { json: "startTime", js: "startTime", typ: Date },
      { json: "endTime", js: "endTime", typ: Date },
      { json: "exercises", js: "exercises", typ: a(r("Exercise")) },
      { json: "isTimed", js: "isTimed", typ: true },
      { json: "isProcessed", js: "isProcessed", typ: u(true, null) },
      {
        json: "coinCalculation",
        js: "coinCalculation",
        typ: u(r("WorkoutCoinCalculation"), null),
      },
      { json: "internalId", js: "internalId", typ: "" },
    ],
    false
  ),
  WorkoutCoinCalculation: o(
    [
      { json: "total", js: "total", typ: 0 },
      { json: "multipliers", js: "multipliers", typ: r("Multipliers") },
      { json: "breakdown", js: "breakdown", typ: r("FluffyBreakdown") },
    ],
    false
  ),
  FluffyBreakdown: o([{ json: "workout", js: "workout", typ: 0 }], false),
  Exercise: o(
    [
      { json: "exerciseCode", js: "exerciseCode", typ: 0 },
      { json: "isUser", js: "isUser", typ: true },
      { json: "exerciseName", js: "exerciseName", typ: "" },
      { json: "startTime", js: "startTime", typ: Date },
      { json: "reps", js: "reps", typ: 0 },
      { json: "weight", js: "weight", typ: 3.14 },
      { json: "unit", js: "unit", typ: r("Unit") },
      { json: "isProcessed", js: "isProcessed", typ: null },
      { json: "coins", js: "coins", typ: null },
    ],
    false
  ),
  ActivityType: ["Cycle", "Hike", "Run"],
  DistanceType: ["K"],
  Source: ["Strava", "Wahoo"],
  UserID: ["opRNF0Xv5XTdtnj8aKfoTZ3Ir312"],
  Unit: ["Kgs", "Lbs"],
};
