import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as GoogleLogo } from "../../assets/google.svg";
import { ReactComponent as AppleLogo } from "../../assets/apple.svg";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Divider, Grid, Paper, Typography, useMediaQuery } from "@mui/material";

import { ReactComponent as AppleStore } from "../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg";
import GoggleStore from "../../assets/google-play-badge.png";

import {
  EmailAndPasswordActionHook,
  useAuthState,
  useSignInWithApple,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";

import styles from "./firebase-login.module.css";
import { authActions, RootState, useAppDispatch } from "../../store";
import { firebaseAuthService } from "../../firebase";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { Alert, Button, TextField } from "@mui/material";
import { Loading } from "../common/loading";
import { Container } from "@mui/system";
import { logger } from "../../logger";

const FirebaseLogin = () => {
  const authError = useSelector((x: RootState) => x.auth.error);

  const matches = useMediaQuery('(min-width:1536px)');


  const [user, loading, error] = useAuthState(firebaseAuthService.firebaseAuth);  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const svgSize = 48;

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  type FormValues = {
    username: string;
    password: string;
  };
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } =
    useForm<FormValues>(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    } else if (error) {
      logger.log(error);
    }

    if (user) {
      dispatch(authActions.getUser({}));
      dispatch(authActions.getUserStats({}));
      navigate("/dashboard");
    }
  }, [user, loading, error, navigate]);

  async function onSubmit(data: FieldValues) {
    return await dispatch(
      authActions.logInWithEmailAndPassword({
        username: data["username"],
        password: data["password"],
      })
    );
  }

  async function loginWithGoogle() {
    return await dispatch(authActions.logInWithGoogle({}));
  }

  async function loginWithApple() {
    return await dispatch(authActions.loginWithApple({}));
  }

  let content =
    loading ? (
      <div className={styles.login}>
        <Loading size={400} />
      </div>
    ) : (
      <div className={styles.login}>
        <div className={styles.login__container}>    
          <Typography fontFamily={"Silkscreen"} className={styles.header}>
            Get the app
          </Typography>
          <div className={styles.appstorecontainer}>
            <div className={styles.appstore}>
              <a
                href="https://apps.apple.com/us/app/athloy/id1585506500"
                target={"#"}
              >
                <AppleStore />
              </a>
            </div>
            <div className={styles.appstore}>
              <a
                href="https://play.google.com/store/apps/details?id=com.irontrack.athloy"
                target={"#"}
              >
                <img src={GoggleStore} alt="fireSpot" />
              </a>
            </div>
          </div>
          <Divider variant="middle" />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.login__form}
          >
            <div className="form-group">
              <TextField
                size={!matches ? 'small' : 'medium'}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                {...register("username")}
                className={[
                  `form-control ${errors.username ? "is-invalid" : ""}`,
                  styles.login_form,styles.login_text
                ].join(" ")}
              />              
              <div className="invalid-feedback">{errors.username?.message}</div>
            </div>
            <div className="form-group">
              <TextField
              size={!matches ? 'small' : 'medium'}
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                {...register("password")}
                className={[
                  `form-control ${errors.password ? "is-invalid" : ""}`,
                  styles.login_form, styles.login_text
                ].join(" ")}
              />              
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              sx={{marginTop: '5px'}}
              className={styles.btn_login}
              type="submit"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Login
            </Button>
            {
              authError && <Alert severity="error" className={styles.errorbox}>{authError}</Alert>              
            }
          </form>
          <Button
            startIcon={<GoogleLogo width={svgSize} height={svgSize} />}
            variant="contained"
            disabled={isSubmitting}
            className={[styles.btn_login, styles.btn_social].join(" ")}
            onClick={loginWithGoogle}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Login using Google
          </Button>
          <Button
            startIcon={<AppleLogo width={svgSize} height={svgSize} />}
            variant="contained"
            disabled={isSubmitting}
            className={[styles.btn_login, styles.btn_social].join(" ")}
            onClick={loginWithApple}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Login using Apple
          </Button>
        </div>
      </div>
    );

  return (
    <Box
      component="main"
      display="flex"
      justifyContent="center"
      alignItems="center"      
    >
      <Container>
        <Paper elevation={3}>{content}</Paper>
      </Container>
    </Box>
  );
};
export default FirebaseLogin;
