import React, { useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./components/private_route";
import Landing from "./Login";
import { RootState } from "./store";
import { lightTheme, darkTheme } from "./theme";
import Dashboard from "./components/dashboard/dashboard";
import DebugRouter from "./helpers/debug-router";

function App() {
  
  const ref = React.useRef<HTMLDivElement>(null);

  const darkMode = useSelector(
    (state: RootState) => state.settings.settings?.darkMode
  );

  const [selectedTheme, setSelectedTheme] = useState(lightTheme);
  useEffect(() => {
    setSelectedTheme(darkMode ? darkTheme : lightTheme);
  }, [darkMode]);

  return (
    <ThemeProvider theme={selectedTheme}>
      <BrowserRouter>
        <DebugRouter>
          <Routes>
            <Route path="/" element={<Landing />} />            
            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
          </Routes>
        </DebugRouter>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
