import moment from "moment";
import { AthloyActivityDetail } from "../models/athloy-activity-detail";
import { Activity, ActivityType, AthloyFeedItem, Workout } from "../models/athloy-feed-item";
import {
  UserActivityAvgPeriodStats,
  UserActivityAvgStats,
} from "../models/user-activity-avg-stats";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { filter } from "rxjs";
import {
  UserWorkoutAvgStats,
  UserWorkoutPeriodStats,
} from "../models/user-workout-avg-stats";
import { AhtloyUserStats, AthleteStats, AthloyUser } from "../models/athloy-user";
import { AthloyExercise, AthloyWorkoutExercise } from "../models/athloy-exercise";
import {Buffer} from 'buffer';
import { logger } from "../logger";

const apiUrl = process.env.REACT_APP_API_URL;

export interface ActivitySort {
  fieldName: string;
  direction: string;
}

export interface ActivityFilter {
  fieldName: string;
  operator: string;
  value: any;
}

export const getFeedData = async (
  fromDate: Date,
  toDate: Date
): Promise<void | AthloyFeedItem[]> => {
  const result = await fetchWrapper
    .get(
      `${apiUrl}api/User/feed?page=1&pageSize=100&fromDate=${moment(
        fromDate
      ).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}`
    )
    .then((e: AthloyFeedItem[]) => {
      return e.map((i: AthloyFeedItem) => {
        const result: AthloyFeedItem = {
          ...i,
          itemDate: new Date(i.itemDate),
        };
        return result;
      });
    })
    .catch((e) => {
      return [];
    });

  const feedItems = await result;
  return feedItems;
};

export const getWorkouts = async (
  sorts: ActivitySort[] | null,
  userFilters: ActivityFilter[] | null
): Promise<void | Workout[]> => {
  const filters: any[] = [];

  userFilters?.forEach((f) => {
    filters.push({
      fieldName: f.fieldName,
      operatorName: f.operator,
      fieldValue: f.value,
    });
  });

  let qryStr = "";
  for (let index = 0; index < filters.length; index++) {
    qryStr +=
      `&filters[${index}].fieldName=${filters[index].fieldName}&` +
      `filters[${index}].operatorName=${filters[index].operatorName}&` +
      `filters[${index}].value=${filters[index].fieldValue}`;
  }

  for (let index = 0; index < (sorts?.length ?? 0); index++) {
    qryStr += `&sorts[${sorts![index].fieldName}]=${sorts![index].direction}`;
  }

  const result = await fetchWrapper
    .get(`${apiUrl}api/workout/query?page=1&pageSize=20${qryStr}`)
    .then((e: Workout[]) => {
      return e.map((i: Workout) => {
        const result: Workout = {
          ...i,
          startTime: new Date(i.startTime),
          endTime: new Date(i.endTime),
        };
        return result;
      });
    })
    .catch((e) => {
      return [];
    });

  return result;
};

export const getWorkout = async (id: string): Promise<Workout | null> => {
  const result = await fetchWrapper
    .get(`${apiUrl}api/workout/${id}`)
    .then((e: Workout) => {
      const workout: Workout = {
        ...e,
        startTime: new Date(e.startTime),
        endTime: new Date(e.endTime),
      };
      return workout;
    })
    .catch((e) => {
      return null;
    });
  return result;
};

export const getActivities = async (
  type: string,
  sorts: ActivitySort[] | null,
  userFilters: ActivityFilter[] | null
): Promise<void | Activity[]> => {
  const filters = [
    {
      fieldName: "activityType",
      operatorName: "eq",
      fieldValue: type,
    },
  ];

  userFilters?.forEach((f) => {
    filters.push({
      fieldName: f.fieldName,
      operatorName: f.operator,
      fieldValue: f.value,
    });
  });

  //1&Sorts[activityDate]=desc&Filters[0].FieldName=activityId&Filters[0].OperatorName=ne&Filters[0].Value=56086167181212&Filters[1].FieldName=activityDate&Filters[1].Value=2021-07-11&Filters[1].OperatorName=gte&Filters[2].FieldName=activityDate&Filters[2].Value=2021-07-13&Filters[2].OperatorName=lte
  let qryStr = "";
  for (let index = 0; index < filters.length; index++) {
    qryStr +=
      `&filters[${index}].fieldName=${filters[index].fieldName}&` +
      `filters[${index}].operatorName=${filters[index].operatorName}&` +
      `filters[${index}].value=${filters[index].fieldValue}`;
  }

  for (let index = 0; index < (sorts?.length ?? 0); index++) {
    qryStr += `&sorts[${sorts![index].fieldName}]=${sorts![index].direction}`;
  }

  const httpResult = await fetchWrapper.get(
    `${apiUrl}api/Activity/query?page=1&pageSize=20${qryStr}`
  );

  const result = await httpResult;
  return result;
};

export type GetActivitiesGridResult = {
  count:number;
  activities:Activity[];
}

export const getActivitiesGrid = async (
  type: ActivityType,
  page: number,
  pageSize: number,
  sorts: ActivitySort[] | null,
  userFilters: ActivityFilter[] | null
): Promise<GetActivitiesGridResult> => {
  const filters = [
    {
      fieldName: "activityType",
      operatorName: "eq",
      fieldValue: type,
    },
  ];

  userFilters?.forEach((f) => {
    filters.push({
      fieldName: f.fieldName,
      operatorName: f.operator,
      fieldValue: f.value,
    });
  });

  let qryStr = "";
  for (let index = 0; index < filters.length; index++) {
    qryStr +=
      `&filters[${index}].fieldName=${filters[index].fieldName}&` +
      `filters[${index}].operatorName=${filters[index].operatorName}&` +
      `filters[${index}].value=${filters[index].fieldValue}`;
  }

  for (let index = 0; index < (sorts?.length ?? 0); index++) {
    qryStr += `&sorts[${sorts![index].fieldName}]=${sorts![index].direction}`;
  }

  const httpResult = await fetchWrapper.get(
    `${apiUrl}api/activity/gridquery?page=${page}&pageSize=${pageSize}${qryStr}`
  );

  const result = await httpResult;
  return result;
};

export const getActivityDetail = async (
  activityId: string,
  includeStreams: boolean
): Promise<AthloyActivityDetail | null> => {
  try {
    const result = await fetchWrapper.get(`${apiUrl}api/activity/${activityId}?includeStreams=${includeStreams}`);
    logger.log(result);
    const activity = await result as AthloyActivityDetail | null;
    if (activity != null) {

      if (activity.dataStreams?.hr != null) {        
        activity.dataStreams.hrData = Buffer.from(activity.dataStreams.hr, 'base64');        
      }

    }
    return activity;
  } catch (ex) {
    logger.log(`Error in getActivityDetail. ${ex}}`);
    return null;
  }
};

export const getUserAvgWorkoutDataPastMonths = async (
  monthPeriod: number
): Promise<UserWorkoutAvgStats | null> => {
  const fromDate = moment(Date()).add(monthPeriod * -1, "M");

  try {
    const result = await fetchWrapper.get(
      `${apiUrl}api/User/stats/workouts/avg?fromDate=${fromDate.toISOString()}`
    );
    const mockItems = await result;
    return mockItems;
  } catch (ex) {
    return null;
  }
};

export const getUserAvgDataPastMonths = async (
  activityType: string,
  monthPeriod: number
): Promise<UserActivityAvgStats | null> => {
  const fromDate = moment(Date()).add(monthPeriod * -1, "M");

  try {
    const result = await fetchWrapper.get(
      `${apiUrl}api/User/stats/activities/avg?activityType=${activityType}&fromDate=${fromDate.toISOString()}`
    );
    const mockItems = await result;
    return mockItems;
  } catch (ex) {
    return null;
  }
};

export const getExerciseData = async (): Promise<AthloyExercise[] | null> => {
  try {
    return await fetchWrapper
      .get(`${apiUrl}api/Exercise`)
      .then(async function (response) {
        const apiExercises = (await response) as AthloyExercise[];
        return apiExercises;
      });
  } catch (ex) {
    return null;
  }
};

export const getExerciseWorkoutData = async (exerciseCode:string, isUser:boolean): Promise<AthloyWorkoutExercise[] | null> => {
  try {
    return await fetchWrapper
      .get(`${apiUrl}api/workout/exercises?fromDate=${new Date(2020,1,2).toISOString()}&exerciseCode=${exerciseCode}&isUser=${isUser}`)
      .then(async function (response) {
        const apiExercises = (await response) as AthloyWorkoutExercise[];
        return apiExercises;
      });
  } catch (ex) {
    return null;
  }
};

export const getUserStats = async (): Promise<AhtloyUserStats | null> => {
  try {
    const result = await fetchWrapper
      .get(`${apiUrl}api/user/stats`)
      .then(async function (response) {
        const apiStats = await response;
        return apiStats as AhtloyUserStats;
      });
    return result;
  } catch (ex) {
    return null;
  }
};

export const getUserStatsPeriod = async (
  activityType: string,
  period: string,
  fromDate: Date
): Promise<UserActivityAvgPeriodStats[] | null> => {
  try {
    const query = await fetchWrapper.get(
      `${apiUrl}api/User/stats/activities/avg/${period}?activityType=${activityType}&fromDate=${fromDate.toISOString()}`
    );
    const results = await query;
    return results;
  } catch (ex) {
    return null;
  }
};

export const getUserWeeklyWorkoutStats = async (
  fromDate: Date
): Promise<UserWorkoutPeriodStats[] | null> => {
  try {
    const query = await fetchWrapper.get(
      `${apiUrl}api/User/stats/workouts/weekly?fromDate=${fromDate.toISOString()}&pageSize=100`
    );
    const results = await query;
    return results;
  } catch (ex) {
    return null;
  }
};

export const getUser = async (): Promise<AthloyUser | null> => {
  try {
    const result = await fetchWrapper
      .get(`${apiUrl}api/user`)
      .then(async function (response) {
        const user = await response;
        return user;
      });
    return result;
  } catch (ex) {
    return null;
  }
};

export const putUserStats = async (user:AthleteStats): Promise<AthloyUser | null> => {
  try {
    const result = await fetchWrapper
      .put(`${apiUrl}api/user/stats`, user)
      .then(async function (response) {
        const user = await response;
        return user;
      });
    return result;
  } catch (ex) {
    return null;
  }
};
