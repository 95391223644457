import moment from "moment";

export enum DistanceUnitType {
  miles = "Miles",
  km = "KM",
}

export enum ElevationUnitType {
  meters = "m",
  feet = "ft",
}

export enum WeightUnitType {
  kgs = "kgs",
  lbs = "lbs",
}

export const METERS_IN_MILE = 1609;

export default class UnitsHelper {
  unitSpeedToMetersPerSecond(value: number): number {
    return (value * this.multiplier) / 60 / 60;
  }
  
  unitDistanceToMeters(unitDistance: number): number {
    return this.multiplier * unitDistance;
  }

  unitElevationToMeters(unitDistance: number): number {
    return unitDistance / this.elevMultiplier;
  }

  private _weightUnitsLabel: string;
  weightInKgsToWeight(value: number): number {
    return this._weightUnits == WeightUnitType.kgs ? value : value * 2.2;
  }

  weightInKgsToWeightText(value: number): String {
    return isNaN(value)
      ? ""
      : `${this.weightInKgsToWeight(value).toFixed(2)} ${
          this._weightUnitsLabel
        }`;
  }

  speedText(value: number): string {
    return `${value.toFixed(2)} ${this.speedLabel}`;
  }

  speedCalc(distanceInMeters: any, durationInSeconds: any): number {
    return distanceInMeters / this.multiplier / (durationInSeconds / 60 / 60);
  }

  speedCalcText(distanceInMeters: any, durationInSeconds: any): string {
    return `${(
      distanceInMeters /
      this.multiplier /
      (durationInSeconds / 60 / 60)
    ).toFixed(2)} ${this.speedLabel}`;
  }

  public get elevationLabel(): string {
    return this._elevUnits;
  }

  paceCalcText(
    durationInSeconds: number,
    distanceInMeters: number,
    includeUnitLabel: boolean = true
  ) {
    let result = `${moment
      .utc((durationInSeconds / (distanceInMeters / this.multiplier)) * 1000)
      .format("mm:ss")}`;

    if (includeUnitLabel) {
      result += ` min/${this.unitLabel}`;
    }
    return result;
  }

  durationText(durationInSeconds: number) {
    return moment.utc(durationInSeconds * 1000).format("HH:mm:ss");
  }
  metersToDistance(value: number) {
    return value * this.multiplier;
  }

  public get distanceUnitMeters(): number {
    return this.multiplier;
  }

  private _distanceUnits = DistanceUnitType.km;
  private _elevUnits = ElevationUnitType.meters;
  private _weightUnits = WeightUnitType.kgs;

  private multiplier: number;
  private _unitLabel: String;
  private elevMultiplier: number;
  private elevUnitLabel: string;

  public get unitLabel(): String {
    return this._unitLabel;
  }

  public get weightUnitLabel(): String {
    return this._weightUnitsLabel;
  }

  public get speedLabel(): string {
    return this._distanceUnits == DistanceUnitType.km ? "kph" : "mph";
  }

  constructor(
    distanceUnits: string,
    elevationUnits: string,
    weightUnits: string
  ) {
    this._distanceUnits =
      distanceUnits == "mi" ? DistanceUnitType.miles : DistanceUnitType.km;
    this._elevUnits =
      elevationUnits == "ft"
        ? ElevationUnitType.feet
        : ElevationUnitType.meters;
    this._weightUnits =
      weightUnits == "lbs" ? WeightUnitType.lbs : WeightUnitType.kgs;

    this._weightUnitsLabel = weightUnits;

    this.multiplier = 1000;
    this._unitLabel = "km";
    if (this._distanceUnits === DistanceUnitType.miles) {
      this.multiplier = 1609;
      this._unitLabel = "mi";
    }

    this.elevMultiplier = 1;
    this.elevUnitLabel = "m";
    if (this._elevUnits === ElevationUnitType.feet) {
      this.elevMultiplier = 3.28084;
      this.elevUnitLabel = "ft";
    }
  }

  public elevationValue(meterValue: number) {
    return meterValue * this.elevMultiplier;
  }

  public distanceText(value: number, includeUnitLabel: boolean = true) {
    let result = `${(value / this.multiplier).toFixed(2)}`;
    if (includeUnitLabel) {
      result += ` ${this._unitLabel}`;
    }
    return result;
  }

  public elevationText(value: number, includeUnitLabel: boolean = true) {
    let result = `${(value * this.elevMultiplier).toFixed(2)}`;
    if (includeUnitLabel) {
      result += ` ${this.elevUnitLabel}`;
    }
    return result;
  }
  public paceText(value: number, showUnits: boolean = true) {
    return `${moment.utc(value * 1000).format("mm:ss")}${
      showUnits ? `/${this._unitLabel}` : ""
    }`;
  }
  public paceCalc(durationInSeconds: number, distanceInMeters: number) {
    return durationInSeconds / (distanceInMeters / this.multiplier);
  }

  public metersPerSecondPaceText(speed: number, includeUnitLabel:boolean = true): String {
    const label = includeUnitLabel === true ? ` min/${this._unitLabel}` : '';
    return `${moment
      .utc((this.multiplier / speed) * 1000)
      .format("mm:ss")}${label}`;
  }

  public metersPerSecondPace(speed: number): number {
    return this.multiplier / speed;
  }

  public metersPerSecondToSecondsPerUnit(speed: number) {
    return speed > 0 ? this.multiplier / speed : 0;
  }

  public metersPerSecondToSpeedPerHourText(speed: number, includeUnitLabel: boolean = true): string {
    const label = includeUnitLabel ?  this.speedLabel : '';
    return `${this.metersPerSecondToSpeedPerHour(speed).toFixed(2)} ${
      label
    }`;
  }

  public metersPerSecondToSpeedPerHour(speed: number) {
    return ((speed > 0 ? speed : 0) * 60 * 60) / this.multiplier;
  }

  public secondsPerUnitToMetersPerSecond(seconds: number) {    
    return parseFloat((this.multiplier / seconds).toFixed(2));    
  }

  // public secondsPerUnitToSpeedPerHourText(seconds: number): string {
  //   return `${(seconds > 0 ? 3600 / seconds : 0).toFixed(2)} ${
  //     this.speedLabel
  //   }`;
  // }

  public secondsPerUnitToPacePerUnitText(
    seconds: number,
    includeUnitLabel: boolean = true
  ): string {
    return this.paceCalcText(seconds, 1 * this.multiplier, includeUnitLabel);
  }
}
