import { capitalizeFirstLetter } from "../../../helpers/string-helpers";
import {
  Activity,
  ActivityType,
  Exercise,
  Unit,
  Workout,
  WorkoutCoinCalculation,
} from "../../../models/athloy-feed-item";

export class WorkoutGridModel implements Workout {
  public id: number;
  public id_str: string;
  public userId: string;
  public startTime: Date;
  public endTime: Date;
  public exercises: Exercise[];
  public isTimed: boolean;
  public isProcessed: boolean | null;
  public coinCalculation: WorkoutCoinCalculation | null;
  public internalId: string;

  public get totalSets(): number {
    return this.exercises.length;
  }

  public get totalReps(): number {
    return this.exercises.map((e) => e.reps).reduce((p, c) => p + c);
  }

  public get totalWeightInKgs(): number {
    return this.exercises
      .map((e) => {
        return e.unit == Unit.Kgs ? e.weight : e.weight / 2.2;
      })
      .reduce((p, c) => p + c);
  }

  public get durationInSeconds(): number | null {
    return this.isTimed
      ? (this.endTime.getTime() - this.startTime.getTime()) / 1000
      : null;
  }

  public get exercisesStr(): string | null {
    return [
      ...new Set(
        this.exercises.map((m) => capitalizeFirstLetter(m.exerciseName))
      ),
    ].join(", ");
  }

  constructor(workout: Workout) {
    this.id = workout.id;
    this.id_str = workout.id_str;
    this.userId = workout.userId;
    this.startTime = workout.startTime;
    this.endTime = workout.endTime;
    this.exercises = workout.exercises;
    this.isTimed = workout.isTimed;
    this.isProcessed = workout.isProcessed;
    this.coinCalculation = workout.coinCalculation;
    this.internalId = workout.internalId;
  }
}
