import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InsightsIcon from "@mui/icons-material/Insights";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ReactComponent as Kettlebell } from "../../assets/images/kettlebell.svg";
import { ReactComponent as Bike } from "../../assets/images/bike.svg";
import { ReactComponent as RunShoe } from "../../assets/images/run.svg";
import { ReactComponent as Heart } from "../../assets/images/heart.svg";
import { ReactComponent as Barbell } from "../../assets/images/barbell.svg";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { ListItem, useTheme } from "@mui/material";
import { logger } from "../../logger";

function ListItemLink(props: any) {
  const { icon, primary, to, sx, path } = props;
  const theme = useTheme();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(
        (linkProps, ref) => {
          return <Link ref={ref} to={to} {...linkProps} />;
        }
      ),
    [to]
  );

  return (
    <ListItem    
      sx={{        
        padding: 0,
        backgroundColor: path?.endsWith(to)
          ? theme.palette.background.default
          : "inherit",
        // // selected and (selected + hover) states
        // "&& .Mui-selected, && .Mui-selected:hover": {
        //   bgcolor: "red",
        //   "&, & .MuiListItemIcon-root": {
        //     color: "pink",
        //   },
        // },
        // hover states
        "& .MuiListItemButton-root:hover": {
          bgcolor: theme.palette.primary.main,
          "&, & .MuiListItemIcon-root": {
            //color: "yellow",
          },
        },
      }}
    >
      <ListItemButton component={CustomLink} sx={sx}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: path?.endsWith(to) ? "bold" : "inherit",
          }}
          primary={primary}
        />
      </ListItemButton>
    </ListItem>
  );
}
export const MainListItems = () => {
  // let path = location && location.pathname.split("/");
  // let team = path && path[1];
  const location = useLocation();

  const [path, setPath] = React.useState(location.pathname);

  React.useEffect(() => {
    setPath(location.pathname);
    logger.log(`PATH:${location.pathname}`);
  }, [location]);

  return (
    <React.Fragment>
      <ListItemLink
        path={path}
        icon={<InsightsIcon />}
        primary="Summary"
        to="/dashboard/summary"
      ></ListItemLink>
      <ListItemLink
        path={path}
        icon={<CalendarMonthIcon />}
        primary="Logbook"
        to="/dashboard/calendar"
      ></ListItemLink>
      <ListItemLink
        path={path}
        icon={<Kettlebell />}
        primary="Workouts"
        to="/dashboard/workouts"
      ></ListItemLink>
      <ListItemLink
        path={path}
        icon={<RunShoe />}
        primary="Runs"
        to="/dashboard/runs"
      ></ListItemLink>
      <ListItemLink
        path={path}
        icon={<Bike />}
        primary="Rides"
        to="/dashboard/rides"
      ></ListItemLink>
      <ListItemLink
        path={path}
        icon={<Heart />}
        primary="Heart Rate Zones"
        to="/dashboard/hrzones"
      ></ListItemLink>
      <ListItemLink
        path={path}
        icon={<Barbell />}
        primary="Exercises"
        to="/dashboard/exercises"
      ></ListItemLink>
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
