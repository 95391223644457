import SpritePage from "../../assets/images/loading.png";
import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    background-position-y: 0
  }
  100% { 
    background-position-y: 120%
   }
`;

const LoadingImage = styled.div`
  height: 100%;
  width: 100%;
  image-rendering: pixelated;
  background: url(${SpritePage}) left center;
  animation: ${animation} 2s steps(6) infinite;
  background-size: cover;
`;

const Container = styled.div`
  font-family: "Silkscreen";
  text-align: center;
`;

type LoadingProps = {
  size?: number | null;
};
export const Loading = (props: LoadingProps) => {
  const width = props.size ?? "100%";
  const height = props.size != null ? props.size / 4 : "25%";

  return (
    <div>
      <div style={{ height: height, width: width, margin: "auto" }}>
        <LoadingImage></LoadingImage>
      </div>
      <Container>
        <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
          Loading...
        </div>
      </Container>
    </div>
  );
};

