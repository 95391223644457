import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

type PeriodProps = {
    initialPeriod : number;
    onChange: (value: number) => void;            
}

export default function AvgPeriodToggleButtons({initialPeriod, onChange} : PeriodProps) {
  const [period, setPeriod] = useState<number>(initialPeriod);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newPeriod: number
  ) => {
    setPeriod(newPeriod);
    onChange(newPeriod);
  };

  return (
    <ToggleButtonGroup
      value={period}
      color="primary"
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value={1} aria-label="1 Month">
        1 Month
      </ToggleButton>
      <ToggleButton value={6} aria-label="6 Month">
        6 Months
      </ToggleButton>
      <ToggleButton value={12} aria-label="1 Year">
        1 Year
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
