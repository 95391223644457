import { green, red } from "@mui/material/colors";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

export const AthloyRed = {
50: '#ffe4e9',
  100: '#febcc8',
  200: '#fe8fa4',
  300: '#fd6280',
  400: '#fc4164',
  500: '#fc1f49',
  600: '#fc1b42',
  700: '#fb1739',
  800: '#fb1231',
  900: '#fa0a21',
  A100: '#ffffff',
  A200: '#fff0f1',
  A400: '#ffbdc1',
  A700: '#ffa3aa',
};

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#393939",
    },
    primary: {
      main: AthloyRed[500],
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      //main: red.A400,
      main: red.A700,
    },
    success: {
      main: green.A700,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#4A4A4A",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem",
          padding: "7px",
          "@media (min-width:1200px)": {
            fontSize: "0.875rem",
            padding: "11px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem",
          "@media (min-width:1200px)": {
            fontSize: "0.875rem",
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ebebeb",
    },
    primary: {
      main: AthloyRed[500],
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      //main: red.A400,
      main: red.A700,
    },
    success: {
      main: green.A700,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#eeeeee",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem",
          padding: "7px",
          "@media (min-width:1200px)": {
            fontSize: "0.875rem",
            padding: "11px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem",
          "@media (min-width:1200px)": {
            fontSize: "0.875rem",
          },
        },
      },
    },
  },
});

export const zoneColors = [
  "#2196F3",
  "#4CAF50",
  "#FFEB3B",
  "#FF9800",
  "#F44336",
];
