import {
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { catchError, filter, first, from, map, Observable } from "rxjs";
import {
  getActivityDetail,
  getExerciseData,
  getFeedData,
  getUserAvgDataPastMonths,
  getUserAvgWorkoutDataPastMonths,
  getWorkout,
  //from "../../services/mock-data";
} from "../../../services/http-api-data-service";
import UnitsHelper from "../../../helpers/units-helper";
import { Activity, AthloyFeedItem } from "../../../models/athloy-feed-item";
import { UserWorkoutAvgStats } from "../../../models/user-workout-avg-stats";
import AvgPeriodToggleButtons from "../../common/avg-period-toggle";
import { Loading } from "../../common/loading";
import { ActivityChart } from "../../common/activity-chart";
import { AthloyActivityDetail } from "../../../models/athloy-activity-detail";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { WorkoutGridModel } from "../workouts/workout-grid-model";
import WorkoutSetsTimeline from "./workout-sets-timeline";
import { AthloyExercise } from "../../../models/athloy-exercise";
import MuscleSprite from "../muscle-sprites/muscle-sprites";
import styles from "./workout-detail.module.css";
import { logger } from "../../../logger";

enum Status {
  Loading,
  Loaded,
  Error,
}

const WorkoutDetail = (model: {}) => {
  const params = useParams();
  const navigate = useNavigate();

  //let avgStats:UserWorkoutAvgStats | null = null;;

  const size = window.innerWidth > 1450 ? 400 : 200;

  const [workoutData, setWorkoutData] = useState<WorkoutGridModel | null>(null);
  const [status, setStatus] = useState<Status>(Status.Loading);
  const [avgPeriod, setAvgPeriod] = useState<number>(1);

  const settings = useSelector((state: RootState) => state.settings.settings);
  const unitsHelper = new UnitsHelper(
    settings.distanceUnits,
    settings.elevationUnits,
    settings.weightUnits
  );

  const [avgStats, setAvgStats] = useState<UserWorkoutAvgStats | null>(null);
  const [exercises, setExercises] = useState<AthloyExercise[]>([]);

  useEffect(() => {
    const asyncLoad = async () => {
      let ex = (await getExerciseData()) ?? [];
      setExercises(ex);
    };
    asyncLoad();
  }, []); // run once

  useEffect(() => {
    const asyncLoad = async () => {
      let avg = (await getUserAvgWorkoutDataPastMonths(avgPeriod)) ?? null;

      setAvgStats(avg);
    };
    asyncLoad();
  }, [avgPeriod]);

  useEffect(() => {
    const asyncLoad = async () => {
      try {
        let act = (await getWorkout(params.id!)) ?? null;
        if (act != null) {
          let workoutModel = new WorkoutGridModel(act!);
          setWorkoutData(workoutModel);
        } else {
          setWorkoutData(null);
        }
        let avg = (await getUserAvgWorkoutDataPastMonths(avgPeriod)) ?? null;
        setAvgStats(avg);
        setStatus(Status.Loaded);
      } catch (ex) {
        logger.error(ex);
      }
    };
    asyncLoad();
  }, [params.id]); // RUN ONCE

  function renderRunDetail() {
    if (workoutData == null) {
      return (
        <div>
          <Typography>Unable to load workout.</Typography>
        </div>
      );
    }

    let exCodes = [...new Set(workoutData.exercises.map(
      e => e.exerciseCode
    ))];

    let musclesUsed = [...new Set(exercises.filter(ex => exCodes.includes(ex.code))
      .map(e => e.primary_muscles)
      .flat()
    )];


    let durationText = workoutData.durationInSeconds
      ? unitsHelper.durationText(workoutData!.durationInSeconds)
      : "N/A";

    //avgStats!.avgDuration = runData!.durationInSeconds - 100;
    let durationDiff =
      workoutData.durationInSeconds != null
        ? workoutData!.durationInSeconds < avgStats?.avgDuration!
          ? `-${(
            ((avgStats?.avgDuration! - workoutData!.durationInSeconds!) /
              avgStats?.avgDuration!) *
            100
          ).toFixed(2)}%`
          : `+${(
            ((workoutData!.durationInSeconds! - avgStats?.avgDuration!) /
              workoutData!.durationInSeconds!) *
            100
          ).toFixed(2)}%`
        : "";

    let setsDiff =
      workoutData!.totalSets != null
        ? workoutData!.totalSets < avgStats?.avgSets!
          ? `-${(
            ((avgStats?.avgSets! - workoutData!.totalSets!) /
              avgStats?.avgSets!) *
            100
          ).toFixed(2)}%`
          : `+${(
            ((workoutData!.totalSets! - avgStats?.avgSets!) /
              workoutData!.totalSets!) *
            100
          ).toFixed(2)}%`
        : "";

    let repsDiff =
      workoutData!.totalReps != null
        ? workoutData!.totalReps < avgStats?.avgReps!
          ? `-${(
            ((avgStats?.avgReps! - workoutData!.totalReps!) /
              avgStats?.avgReps!) *
            100
          ).toFixed(2)}%`
          : `+${(
            ((workoutData!.totalReps! - avgStats?.avgReps!) /
              workoutData!.totalReps!) *
            100
          ).toFixed(2)}%`
        : "";

    let weightDiff =
      workoutData!.totalWeightInKgs != null
        ? workoutData!.totalWeightInKgs < avgStats?.avgWeight!
          ? `-${(
            ((avgStats?.avgWeight! - workoutData!.totalWeightInKgs!) /
              avgStats?.avgWeight!) *
            100
          ).toFixed(2)}%`
          : `+${(
            ((workoutData!.totalWeightInKgs! - avgStats?.avgWeight!) /
              workoutData!.totalWeightInKgs!) *
            100
          ).toFixed(2)}%`
        : "";

    return (
      <Container disableGutters={true} maxWidth={false}>
        <Grid
          sx={{ paddingX: 0 }}
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Typography variant="h4" fontFamily={"Silkscreen"}>
              WORKOUT
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" onClick={() => navigate(-1)}>
              {"<"} BACK
            </Button>
          </Grid>
          {/* ROW */}
          <Grid item xs={4} md={1}>
            Date:
          </Grid>
          <Grid item xs={8} md={2}>
            {moment(workoutData!.startTime).format("DD MMM YYYY")}
          </Grid>
          <Grid item xs={4} md={2}>
            Average over:
          </Grid>
          <Grid item xs={8} md={7}>
            <AvgPeriodToggleButtons
              initialPeriod={avgPeriod}
              onChange={setAvgPeriod}
            ></AvgPeriodToggleButtons>
          </Grid>
          {/* ROW */}
          <Grid item xs={4} md={1}></Grid>
          <Grid item xs={8} md={2}>
            This Workout
          </Grid>
          <Grid item xs={8} md={2}>
            Average
          </Grid>
          <Grid item xs={8} md={2}>
            % Difference
          </Grid>
          <Grid item xs={8} md={5}></Grid>
          {/* ROW */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* ROW */}
          <Grid item xs={4} md={1}>
            Duration:
          </Grid>
          <Grid item xs={8} md={2}>
            {durationText ? <div>{durationText}</div> : <div>N/A</div>}
          </Grid>
          <Grid item xs={8} md={2}>
            {avgStats?.avgDuration ? (
              <div>{unitsHelper.durationText(avgStats?.avgDuration)}</div>
            ) : (
              <div>N/A</div>
            )}
          </Grid>
          <Grid item xs={8} md={2}>
            <Chip
              label={avgStats?.avgDuration ? durationDiff : "N/A"}
              color={
                avgStats?.avgDuration
                  ? durationDiff.startsWith("+")
                    ? "success"
                    : "error"
                  : "default"
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={8} md={5}></Grid>
          {/* ROW */}
          <Grid item xs={4} md={1}>
            Sets:
          </Grid>
          <Grid item xs={8} md={2}>
            <div>{workoutData!.exercises.length}</div>
          </Grid>
          <Grid item xs={8} md={2}>
            {avgStats?.avgSets ? (
              <div>{avgStats?.avgSets.toFixed(2)}</div>
            ) : (
              <div>N/A</div>
            )}
          </Grid>
          <Grid item xs={8} md={2}>
            <Chip
              label={avgStats?.avgSets ? setsDiff : "N/A"}
              color={
                avgStats?.avgSets
                  ? setsDiff.startsWith("+")
                    ? "success"
                    : "error"
                  : "default"
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={8} md={5}></Grid>

          <Grid item xs={4} md={1}>
            Reps:
          </Grid>
          <Grid item xs={8} md={2}>
            {<div>{workoutData!.totalReps}</div>}
          </Grid>
          <Grid item xs={8} md={2}>
            {avgStats?.avgReps ? (
              <div>{avgStats?.avgReps.toFixed(2)}</div>
            ) : (
              <div>N/A</div>
            )}
          </Grid>
          <Grid item xs={8} md={2}>
            <Chip
              label={avgStats?.avgReps ? repsDiff : "N/A"}
              color={
                avgStats?.avgReps
                  ? repsDiff.startsWith("+")
                    ? "success"
                    : "error"
                  : "default"
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={8} md={5}></Grid>

          <Grid item xs={4} md={1}>
            Weight:
          </Grid>
          <Grid item xs={8} md={2}>
            {
              <div>
                {unitsHelper.weightInKgsToWeightText(
                  workoutData!.totalWeightInKgs
                )}
              </div>
            }
          </Grid>
          <Grid item xs={8} md={2}>
            {avgStats?.avgReps ? (
              <div>
                {unitsHelper.weightInKgsToWeightText(avgStats?.avgWeight)}
              </div>
            ) : (
              <div>N/A</div>
            )}
          </Grid>
          <Grid item xs={8} md={2}>
            <Chip
              label={avgStats?.avgWeight ? weightDiff : "N/A"}
              color={
                avgStats?.avgWeight
                  ? weightDiff.startsWith("+")
                    ? "success"
                    : "error"
                  : "default"
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={8} md={5}></Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {workoutData ? (
          <Container maxWidth={false}>
            <Grid
              sx={{ paddingX: 0 }}
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="top"
            >
              <Grid item xs={6}>
                {/* <ActivityChart model={workoutData!}></ActivityChart> */}
                <Typography align="center" variant='h4' fontFamily={'Silkscreen'}>Sets Timeline</Typography>
                <WorkoutSetsTimeline
                  exercises={exercises!}
                  workoutExercises={workoutData!.exercises}
                ></WorkoutSetsTimeline></Grid>
              <Grid item xs={6}>
              <Typography align="center" variant='h4' fontFamily={'Silkscreen'}>Muscles Worked</Typography>
                <div className={styles['muscle-container']}>
                  <Typography align="center" fontFamily={'Silkscreen'}>{musclesUsed.join(', ')}</Typography>                
                  <MuscleSprite sex={'male'} muscles={musclesUsed}></MuscleSprite>
                </div>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <div></div>
        )}
      </Container>
    );
  }

  return status === Status.Loading ? (
    <Container>
      <Loading size={size} />
    </Container>
  ) : status === Status.Loaded ? (
    renderRunDetail()
  ) : (
    <Container sx={{ textAlign: "center" }}>An Error Occurred</Container>
  );
};
export default WorkoutDetail;
