import React, { useState } from "react";
import Canvas from "./canvas";
import { AthloyRed } from "../../theme";

function FallingPixels() {
  const ref = React.useRef<HTMLDivElement>(null);

  type PixelBlock = {
    rectX: number;
    rectY: number;
    colIndex: number;
    createdTimeMs: number;
  };

  let activePixelCols = new Array<PixelBlock>();

  const MAX_CONCURRENT = 10;
  const velocity = 10;
  const [cols, setCols] = useState(0);
  let colTotals = Array.from({ length: cols }, (_, i) => 0);

  const draw = (ctx: RenderingContext, frameCount: number) => {
    const context = ctx as CanvasRenderingContext2D;

    const size = Math.ceil(ctx.canvas.width / cols);
    const maxHeight = Math.round(ctx.canvas.height / size);

    // Reset if all filled
    if (colTotals.filter((i) => i >= maxHeight).length == colTotals.length) {
      context.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      colTotals = Array.from({ length: cols }, (_, i) => 0);
    }

    context.fillStyle = AthloyRed[500];
    if (activePixelCols.length < MAX_CONCURRENT) {
      const createDate = Date.now();
      if (
        activePixelCols.filter((e) => createDate - e.createdTimeMs < 50)
          .length == 0
      ) {
        // Get random next column index that is not already at max height
        let colIndex = getRandomIndex(cols);
        while (colTotals[colIndex] >= maxHeight) {
          colIndex = getRandomIndex(cols);
        }

        activePixelCols.push({
          rectX: colIndex * size,
          rectY: -size,
          colIndex: colIndex,
          createdTimeMs: createDate,
        });
      }
    }

    activePixelCols.forEach((element) => {
      drawPixel(size, ctx, context, element);
    });
  };

  const [canvasSize, setCanvasSize] = useState({ height: 0, width: 0 });

  React.useEffect(() => {
    setCanvasSize({
      width: document.body.clientWidth,
      height: document.body.clientHeight,
    });

    const ratio = document.body.clientWidth / document.body.clientHeight;

    setCols(ratio > 1 ? 50 : 10);
  }, []);

  function drawPixel(
    size: number,
    ctx: RenderingContext,
    context: CanvasRenderingContext2D,
    pixelData: PixelBlock
  ) {
    if (
      pixelData.rectY + size >=
      ctx.canvas.height - size * colTotals[pixelData.colIndex]
    ) {
      pixelData.rectY = -size;
      colTotals[pixelData.colIndex]++;

      // This column is done, remove from active array
      activePixelCols = activePixelCols.filter((p) => p != pixelData);
    } else {
      context.clearRect(pixelData.rectX, pixelData.rectY, size, size);

      const yInc =
        pixelData.rectY + size + velocity <
        ctx.canvas.height - size * colTotals[pixelData.colIndex]
          ? velocity
          : ctx.canvas.height -
            size * colTotals[pixelData.colIndex] -
            (pixelData.rectY + size);

      pixelData.rectY += yInc;
    }
    context.fillRect(pixelData.rectX, pixelData.rectY, size, size);
  }

  function getRandomIndex(arg0: number): number {
    return Math.floor(Math.random() * arg0);
  }

  return (
    <Canvas
      style={{ position: "absolute" }}
      draw={draw}
      canvasProperties={{
        width: canvasSize.width,
        height: canvasSize.height * 0.86,
      }}
    ></Canvas>
  );
}

export default FallingPixels;
