import React, { CSSProperties, useEffect, useRef } from "react";

type CanvasOptions = {
  context: string;
};

type CanvasProps = {
  draw: (ctx: RenderingContext, frameCount: number) => void;
  canvasProperties?: any;
  options?: CanvasOptions;
  style?: CSSProperties;
};

const Canvas = (props: CanvasProps) => {
  const canvasRef = useCanvas(props.draw, props.options);

  return <canvas ref={canvasRef} {...props.canvasProperties} style={props.style}/>;
};

const useCanvas = (
  draw: (ctx: RenderingContext, frameCount: number) => void,
  options?: CanvasOptions
) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  function resizeCanvasToDisplaySize(
    canvas: HTMLCanvasElement,
    contextId: string
  ) {
    const { width, height } = canvas.getBoundingClientRect();

    if (canvas.width !== width || canvas.height !== height) {
      const { devicePixelRatio: ratio = 1 } = window;
      const context = canvas.getContext(contextId) as CanvasRenderingContext2D;
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      context!.scale(ratio, ratio);
      return true;
    }

    return false;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext(options?.context || "2d");

    //let frameCount = 0;
    let animationFrameId: number;

    //Our draw came here
    if (context && canvas) {
      const render = (frameCount: number) => {
        //frameCount++;
        resizeCanvasToDisplaySize(canvas, options?.context || "2d");
        draw(context, frameCount);
        animationFrameId = window.requestAnimationFrame(render);
      };
      render(0);
    }

    // Cleanup function on unmount
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);
  return canvasRef;
};

export default Canvas;
