import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { addFirebaseListener } from "../firebase";
import { authReducer } from "../reducers/auth-reducer";
import { settingsReducer } from "../reducers/settings-reducer";

// import { authReducer } from './auth.slice';
// import { usersReducer } from './users.slice';

export * from "../reducers/auth-reducer";
// export * from './users.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    //users: usersReducer
    settings: settingsReducer,
  },
  middleware: (gDM) => gDM({serializableCheck: false}).concat([])    
});

addFirebaseListener(store.dispatch, store.getState);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
