import styles from "./gym-sprites.module.css";
import sprite from "../../../assets/images/gym_sprites.png"; // Tell webpack this JS file uses this image
import { Map } from "typescript";

type GymSpriteModel = {
  equipment: string;
};

const GymSprite = (model: GymSpriteModel) => {

    
  return <div className={styles[model.equipment]} />;
};
export default GymSprite;
