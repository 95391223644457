import { logger } from "../logger";
import { authActions } from "../reducers/auth-reducer";
import { store } from "../store";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(
  method: string
): (url: RequestInfo | URL, body?: Object) => Promise<any> {
  return (url: RequestInfo | URL, body?: Object): Promise<any> => {
    const auth = authHeader(url);
    if (auth == null) {
      throw Error("No user auth");
    }
    let init: RequestInit = {};
    init.method = method;
    if (body) {
      init.body = JSON.stringify(body);
    }
    init.headers = new Headers();
    init.headers.append("Content-Type", "application/json");

    if (auth != null) {
      init.headers.append("Authorization", auth!);
    }
    return fetch(url, init)
      .then(handleResponse)
      .catch((e) => {
        logger.error(e);
      });
  };
}

// helper functions

function authHeader(url: RequestInfo | URL): string | null {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.toString().startsWith(process.env.REACT_APP_API_URL!);
  if (!isApiUrl) {
    return "DUMMY AUTH";
  }
  if (isLoggedIn) {
    return `Bearer ${token}`;
  } else {
    return null;
  }
}

function authToken() {
  let state = store.getState();
  return state.auth.user?.token;
}

function handleResponse(response: Response): Promise<any> {
  return response.text().then((text) => {
    if (!response.ok) {
      if ([401, 403].includes(response.status) && authToken()) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        const logout = () => store.dispatch(authActions.logout());
        logout();
      }

      return Promise.reject({ code: response.status, message: text });
    } else {
      const data = text && JSON.parse(text);
      return data;
    }
  });
}
