// Import the functions you need from the SDKs you need
import { FirebaseError, initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  NextOrObserver,
  OAuthProvider,
  onIdTokenChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  Unsubscribe,
  User,
} from "firebase/auth";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const firebaseAuth = getAuth(firebaseApp);

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const signInWithGoogle = async () => {
  try {
    let res = await signInWithPopup(firebaseAuth, googleProvider);
    return res.user;
  } catch (err) {
    console.error(`Google login error: ${err}`);
    throw err; 
  }
};

const signInWithApple = async () => {
  try {
    let res = await signInWithPopup(firebaseAuth, appleProvider);

    // The signed-in user info.
    const user = res.user;

    // Apple credential
    const credential = OAuthProvider.credentialFromResult(res);    

    return { user: user, credential: credential };
  } catch (err) {
    console.error(`Apple login error: ${err}`);
    throw err;
  }
};

const mapErrorToMessage = (error: FirebaseError) => {
  switch (error.code) {
    case "auth/invalid-email":
    case "auth/wrong-password":
      return "Invalid login credentials.";
    case "auth/user-not-found":
      return "Please sign up for an account.";
    case "auth/too-many-requests":
      return "Account is locked.";
    default:
      return "An error occurred.";
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(firebaseAuth, email, password);
  } catch (err) {
    console.error(err);
    throw err;  
  }
};
const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    const user = res.user;    
  } catch (err) {
    console.error(err);    
  }
};
const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(firebaseAuth, email);
    
  } catch (err) {
    console.error(err);
    
  }
};
const logout = () => {
  signOut(firebaseAuth);
};

const idTokenChange = (callBack: NextOrObserver<User>): Unsubscribe => {
  return onIdTokenChanged(firebaseAuth, callBack);
};

export const firebaseAuthService = {
  firebaseAuth,
  //db,
  signInWithGoogle,
  signInWithApple,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  mapErrorToMessage,
  idTokenChange,
};

export const addFirebaseListener = (dispatch: any, getState: any): any => {
  onIdTokenChanged(firebaseAuth, (data) => {
    dispatch({
      type: "TOKEN_UPDATE",
      data: data,
    });
  });
};
