export const capitalizeFirstLetter = (string: String) => {
  let arr = string.split(" ");
  var res = arr.map((element) => {
    return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
  });
  return res.join(" ");
};


export function roundToNearest(gap: number, vals: Array<number>) {
  let dist = new Array<number>();
  vals.forEach((element) => {
    dist.push(Math.abs(element - gap));
  });

  return vals[dist.indexOf(Math.min(...dist))];
}