import styles from "./muscle-sprites.module.css";
import sprite from "../../../assets/images/muscles/body_muscles_male_back.png"; // Tell webpack this JS file uses this image
import { Map } from "typescript";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Typography } from "@mui/material";

type MuscleSpriteModel = {
  sex: string;
  muscles: string[];
};

const MuscleSprite = (model: MuscleSpriteModel) => {
  const userState = useSelector((x: RootState) => x.auth);

  let sexText: string | null = null;
  if (userState.user?.sex == "F") {
    sexText = "female";
  } else if (userState.user?.sex == "M") {
    sexText = "male";
  }

  let sexSpritesFront,
    sexSpritesBack = "";
  if (sexText != null) {
    sexSpritesFront = [styles["body-front"], styles[sexText]].join(" ");
    sexSpritesBack = [styles["body-back"], styles[sexText]].join(" ");
  }

  return sexText == null ? (
    <Typography fontFamily={"Silkscreen"}>Unable to show muscles.</Typography>
  ) : (
    <div className={styles["block-container"]}>
      <div className={styles["sprite-container"]}>
        <div className={sexSpritesFront} />
        {model.muscles
          .filter(
            (m) =>
              styles[`body-front-${m.replace(" ", "-")}`] != null
          )
          .map((m) => (
            <div
              key={`front-${m}`}
              className={[
                styles[`${sexText}-body-front-muscles`],
                styles["muscle-sprites"],
                styles[`body-front-${m.replace(" ", "-")}`],
              ].join(" ")}
            ></div>
          ))}
      </div>
      <div className={styles["sprite-container"]}>
        <div className={sexSpritesBack} />
        {model.muscles
          .filter(
            (m) => styles[`body-back-${m.replace(" ", "-")}`] != null
          )
          .map((m) => (
            <div
              key={`back-${m}`}
              className={[
                styles[`${sexText}-body-back-muscles`],
                styles["muscle-sprites"],
                styles[`body-back-${m.replace(" ", "-")}`],
              ].join(" ")}
            ></div>
          ))}
      </div>
    </div>
  );
};
export default MuscleSprite;
