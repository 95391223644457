import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems } from "./listitems";
import athloyLogo from "../../assets/images/logo.gif";
import styles from "./dashboard.module.css";
import User from "../user/user";
import { Navigate, Route, Routes } from "react-router-dom";

import WorkoutDetail from "../workout-components/workout-detail/workout-detail";

import HrZones from "../hz-zones/hr-zones";
import ErrorBoundary from "../common/error-boundary";
import { lazy, Suspense } from "react"
import { ActivityType } from "../../models/athloy-feed-item";

const DayActivity = lazy(() => import("../day-activity/day-activity"));
const SummaryComponent = lazy(
  () => import("../summary-component/summary-component")
);
const Workouts = lazy(() => import("../workout-components/workouts/workouts"));

const Runs = lazy(() => import("../runs/runs"));

const RunDetail = lazy(() => import("../run-detail/run-detail"));

const Rides = lazy(() => import("../rides/rides"));

const Exercise = lazy(() => import("../exercise/exercise"));

const ActivityDetail = lazy(() => import("../activity-detail/activity-detail"));

// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.athloy.com/">
        Athloy
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

//const mdTheme = createTheme(athloyThemeOptions);

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar color="primary" position="absolute" open={open} enableColorOnDark>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <img className={styles.athloy_logo} src={athloyLogo} alt="Logo" />
          </Typography>
          <User></User>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems></MainListItems>
          <Divider sx={{ my: 1 }} />
          {/* {secondaryListItems} */}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/calendar" element={<DayActivity />} />
              <Route path="/summary" element={<SummaryComponent />} />
              <Route path="/workouts" element={<Workouts />} />
              <Route path="/workout/*">
                <Route
                  path=":id"
                  element={
                    <ErrorBoundary>
                      <WorkoutDetail />
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route
                path="/runs"
                element={
                  <ErrorBoundary>
                    <Runs />
                  </ErrorBoundary>
                }
              />
              <Route path="/run/*">
                <Route
                  path=":id"
                  element={
                    <ErrorBoundary>
                      <ActivityDetail activityType={ActivityType.Run}/>
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route
                path="/rides"
                element={
                  <ErrorBoundary>
                    {" "}
                    <Rides />
                  </ErrorBoundary>
                }
              />
              <Route path="/ride/*">
                <Route
                  path=":id"
                  element={
                    <ErrorBoundary>
                      {" "}
                      <ActivityDetail activityType={ActivityType.Cycle}/>
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route
                path="/hrzones"
                element={
                  <ErrorBoundary>
                    {" "}
                    <HrZones />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/exercises"
                element={
                  <ErrorBoundary>
                    {" "}
                    <Exercise />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/exercises/:id"
                element={
                  <ErrorBoundary>
                    {" "}
                    <Exercise />
                  </ErrorBoundary>
                }
              />
              <Route path="*" element={<Navigate to="/dashboard/summary" />} />
            </Routes>
          </Suspense>
        </Container>
        <Copyright sx={{ pt: 4 }} />
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
