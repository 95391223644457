import { useLocation } from "react-router-dom";
import { logger } from "../logger";

const DebugRouter = ({ children }: { children: any }) => {
    const location = useLocation();

    if (process.env.NODE_ENV === 'development') {
        logger.log(
            `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
        )
    }

    return children;
}
export default DebugRouter;