import { AccountCircle } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { dark } from "@mui/material/styles/createPalette";
import { render } from "@testing-library/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { firebaseAuthService } from "../../firebase";
import { settingsActions } from "../../reducers/settings-reducer";
import { authActions, RootState, useAppDispatch } from "../../store";
import { logger } from "../../logger";

const User = () => {
  //    const [count, setCount] = useState(0);

  const [user] = useAuthState(firebaseAuthService.firebaseAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const settingsState = useSelector((x: RootState) => x.settings);
  const userState = useSelector((x: RootState) => x.auth);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setAuth(event.target.checked);
  };

  const [darkMode, setDarkMode] = useState<boolean>(
    settingsState.settings?.darkMode ?? false
  );

  const [distanceUnits, setDistanceUnits] = useState<string>(
    settingsState.settings?.distanceUnits ?? "mi"
  );

  const [elevationUnits, setElevationUnits] = useState<string>(
    settingsState.settings?.elevationUnits ?? "ft"
  );

  const [weightUnits, setWeightUnits] = useState<string>(
    settingsState.settings?.weightUnits ?? "lbs"
  );

  const handleDarkModeChange = (event: any) => {
    let updateDarkMode = !darkMode;
    setDarkMode(updateDarkMode);
    dispatch(settingsActions.setDarkMode(updateDarkMode));
  };

  const handleDistanceUnits = (
    event: React.MouseEvent<HTMLElement>,
    distanceUnits: string
  ) => {
    setDistanceUnits(distanceUnits);
    dispatch(settingsActions.setDistanceUnit(distanceUnits));
  };

  const handleElevationUnits = (
    event: React.MouseEvent<HTMLElement>,
    elevationUnits: string
  ) => {
    setElevationUnits(elevationUnits);
    dispatch(settingsActions.setElevationUnit(elevationUnits));
  };

  const handleWeightUnits = (
    event: React.MouseEvent<HTMLElement>,
    weightUnits: string
  ) => {
    setWeightUnits(weightUnits);
    dispatch(settingsActions.setWeightUnit(weightUnits));
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    logger.log(`User state:${userState}`);
    if (!user) {
      //navigate("/");
    }
  }, [navigate, user, userState]); // This will only re-run if user changes

  useEffect(() => {
    setDarkMode(settingsState.settings?.darkMode ?? false);
  }, [settingsState]); // This will only re-run if user changes

  function logout() {
    dispatch(authActions.logout());
  }

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        <MenuItem>
          <Grid
            sx={{ paddingX: 0 }}
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              Dark Mode
            </Grid>
            <Grid item xs={6}>
              <Switch
                checked={darkMode}
                onChange={handleDarkModeChange}
                inputProps={{ "aria-label": "controlled" }}
              ></Switch>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem>
          <Grid
            sx={{ paddingX: 0 }}
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              Distance Units
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={distanceUnits}
                color="primary"
                exclusive
                onChange={handleDistanceUnits}
                aria-label="text alignment"
              >
                <ToggleButton value="mi" aria-label="Miles">
                  Miles
                </ToggleButton>
                <ToggleButton value="km" aria-label="Km">
                  Km
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid
            sx={{ paddingX: 0 }}
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              Elevation Units
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={elevationUnits}
                color="primary"
                exclusive
                onChange={handleElevationUnits}
                aria-label="text alignment"
              >
                <ToggleButton value="ft" aria-label="Feet">
                  Feet
                </ToggleButton>
                <ToggleButton value="m" aria-label="Meters">
                  Meters
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid
            sx={{ paddingX: 0 }}
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              Weight Units
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={weightUnits}
                color="primary"
                exclusive
                onChange={handleWeightUnits}
                aria-label="text alignment"
              >
                <ToggleButton value="lbs" aria-label="Lbs">
                  Lbs
                </ToggleButton>
                <ToggleButton value="kgs" aria-label="Kgs">
                  Kgs
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Button
            sx={{ margin: "auto" }}
            variant={"contained"}
            onClick={logout}
          >
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </div>

    // <div>
    //     <div>{user?.displayName}</div>
    //     <button onClick={logout}>LOGOUT</button>
    // </div>
  );
};
export default User;
