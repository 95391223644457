import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Exercise } from "../../../models/athloy-feed-item";
import GymSprite from "../gym-sprites/gym-sprites";
import { AthloyExercise } from "../../../models/athloy-exercise";
import { capitalizeFirstLetter } from "../../../helpers/string-helpers";
import { Link, Typography, useTheme } from "@mui/material";
import moment, { max } from "moment";
import styles from "./workout-detail.module.css";
import { AthloyRed } from "../../../theme";
import { useEffect, useState } from "react";
import { logger } from "../../../logger";
import { useNavigate } from "react-router-dom";

type WorkoutSetsTimelineModel = {
  workoutExercises: Exercise[];
  exercises: AthloyExercise[];
};

const WorkoutSetsTimeline = (props: WorkoutSetsTimelineModel) => {
  const [maxRest, setMaxRest] = useState<number | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setMaxRest(
      Math.max(
        ...props.workoutExercises
          .map((element, index) => {
            if (
              element.startTime &&
              index < props.workoutExercises.length - 1
            ) {
              let timeDiff =
                new Date(
                  props.workoutExercises[index + 1]!.startTime!
                ).getTime() - new Date(element.startTime).getTime();
              return timeDiff;
            }
            return null;
          })
          .filter((e) => e != null)
          .map((e: number | null) => e!)
      )
    );
    logger.log(`MAX REST: ${maxRest}`);
  }, []);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {props.workoutExercises.map((element, index) => {
        var ex = props.exercises.filter(
          (ex) => ex.code === element.exerciseCode
        )[0];
        let eqCode = ex ? ex.equipment : "b";

        let restText = "";
        let timeDiff = null;
        if (element.startTime && index < props.workoutExercises.length - 1) {
          timeDiff =
            new Date(props.workoutExercises[index + 1]!.startTime!).getTime() -
            new Date(element.startTime).getTime();
          logger.log(`DIFF: ${timeDiff}`);
          restText = `Rest ${
            timeDiff > 6000
              ? moment.utc(timeDiff).format("m:ss")
              : `${Math.round(timeDiff / 1000)} seconds`
          }`;
        }

        return (
          <TimelineItem key={`${element.exerciseCode}-${index}`}>
            <TimelineSeparator color="primary">
              <TimelineDot color="primary" sx={{ margin: 0 }}>
                <GymSprite equipment={eqCode} />
              </TimelineDot>
              {index < props.workoutExercises.length - 1 ? (
                window.innerWidth > 1024 ? (
                  <>
                    <TimelineConnector sx={{ width: 6 }}>
                      <div style={{ width: 500, marginLeft: 30 }}>
                        <div
                          style={{
                            backgroundColor: AthloyRed[500],
                            width: `${
                              maxRest && timeDiff
                                ? (timeDiff! / maxRest!) * 100
                                : 0
                            }%`,
                          }}
                          className={styles["rest-text"]}
                        >
                          <div>{restText}</div>
                        </div>
                      </div>
                    </TimelineConnector>
                  </>
                ) : (
                  <TimelineConnector sx={{ width: 6 }}>
                    <div className={styles["rest-text"]}>{restText}</div>
                  </TimelineConnector>
                )
              ) : (
                <></>
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Link
                color={theme.palette.text.primary}
                // onClick={() => {
                //   navigate("/dashboard/exercises");
                // }}
                underline="none"
                href={`/dashboard/exercises/${element.exerciseCode}_${element.isUser}`}
                fontFamily={"Silkscreen"}
              >
                {capitalizeFirstLetter(element.exerciseName)} - {element.reps} x{" "}
                {element.weight}
                {element.unit.toString()}
              </Link>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
export default WorkoutSetsTimeline;
