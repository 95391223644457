import {
  Alert,
  Box,
  BoxProps,
  Button,
  Container,
  Grid,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import { border, color } from "@mui/system";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { number } from "yup";
import { getUser, putUserStats } from "../../services/http-api-data-service";
import { zoneColors } from "../../theme";

const CssTextField = styled(TextField)({
  // '& input': {
  //     color: 'black',
  // },
  // '& label': {
  //     color: 'black'
  // },
  // '& label.Mui-focused': {
  //     color: 'black',
  // },
  // '%:hover': {
  //     borderBottomColor: 'black',
  // },
  // '& .MuiInput-underline:after': {
  //     borderBottomColor: 'black',
  // },
});

const isOnlyNumbers = (str: string) => /^[0-9]*$/.test(str);

enum UpdateStatus {
  success,
  error,
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        // border: '1px solid',
        // borderColor: (theme) =>
        //     theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        // p: 1,
        // borderRadius: 2,
        // textAlign: 'center',
        // fontSize: '0.875rem',
        // fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

function HrZones() {
  const [zones, setZones] = useState([0, 0, 0, 0, 0]);
  const [maxHr, setMaxHr] = useState(220);
  const [age, setAge] = useState<number | null>(null);
  const [restingHr, setRestingHr] = useState<number | null>(null);
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus | null>(null);

  useEffect(() => {
    const asyncLoad = async () => {
      var user = await getUser();
      if (user?.athleteStats.basicInfo.hrZones != null) {
        setZones(user?.athleteStats.basicInfo.hrZones);
      }
      if (user?.athleteStats.basicInfo.restingHr != null) {
        setRestingHr(user?.athleteStats.basicInfo.restingHr);
      }
      if (user?.athleteStats.basicInfo.maxHr != null) {
        setMaxHr(user?.athleteStats.basicInfo.maxHr);
      }
      if (user?.athleteStats.basicInfo.dob != null) {
        setAge(
          moment(Date.now()).diff(user?.athleteStats.basicInfo.dob!, "years")
        );
      }
    };
    asyncLoad();
  }, []);

  function calculateZones() {
    if (restingHr != null) {
      const mappedZones = zones.map((v, i) =>
        Math.floor((maxHr - restingHr!) * (0.5 + 0.1 * i) + restingHr!)
      );
      setZones(mappedZones);
    }
  }

  async function update() {
    try {
      var user = await getUser();
      if (user != null) {
        user.athleteStats.basicInfo.hrZones = zones;
        if (restingHr != null) {
          user.athleteStats.basicInfo.restingHr = restingHr;
        }
        if (maxHr != null) {
          user.athleteStats.basicInfo.maxHr = maxHr;
        }
        await putUserStats(user.athleteStats);
        setUpdateStatus(UpdateStatus.success);
      } else {
        setUpdateStatus(UpdateStatus.error);
      }
    } catch (ex) {
      setUpdateStatus(UpdateStatus.error);
    }
  }

  function setZoneFrom(
    arg0: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const { value } = e.target;
    if (isOnlyNumbers(value)) {
      let num = Number.parseInt(value);
      if (arg0 > 1) {
        updateZone(arg0 - 1, isNaN(num) ? 0 : num);
      } else {
        updateZone(arg0 - 1, isNaN(num) ? 0 : num);
      }
    }
  }

  function updateZone(index: number, value: number) {
    const mapped = zones.map((v, i) => {
      if (i == index) {
        return value;
      } else {
        return v;
      }
    });
    setZones(mapped);
  }

  function setZoneTo(
    arg0: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const { value } = e.target;
    if (isOnlyNumbers(value)) {
      let num = Number.parseInt(value);
      if (arg0 > 1) {
        updateZone(arg0, isNaN(num) ? 0 : num + 1);
      } else {
        updateZone(arg0, isNaN(num) ? 0 : num + 1);
      }
    }
  }

  function setRestingHrInput(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const { value } = e.target;
    if (value == "") {
      setRestingHr(0);
    } else if (isOnlyNumbers(value)) {
      let num = Number.parseInt(value);
      if (!isNaN(num)) {
        setRestingHr(num);
      }
    } else {
      setRestingHr(0);
    }
  }

  function zoneRow(zone: number, color: string): React.ReactNode {
    return (
      <>
        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "row",
            gridTemplateColumns: "repeat(3, 1fr)",
            // gridTemplateRows: 'repeat(1, 1fr)',
            gap: 0,
            justifyContent: "center",
          }}
        >
          <Item
            alignItems="center"
            display="flex"
            justifyContent="center"
            sx={{
              background: color,
              color: "black",
              borderRadius: "1rem 0 0 1rem",
            }}
          >
            <div>ZONE {zone}</div>
          </Item>
          <Item
            alignContent="center"
            sx={{
              background: color + "64",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CssTextField
              type="text"
              size={"medium"}
              id="outlined-basic"
              label="From"
              variant="standard"
              value={zones[zone - 1]}
              onChange={(e) => setZoneFrom(zone, e)}
            />
          </Item>
          <Item
            alignContent="center"
            sx={{
              background: color + "64",
              display: "flex",
              justifyContent: "center",
              borderRadius: "0 1rem 1rem 0",
            }}
          >
            {zone == 5 ? (
              <CssTextField
                type="text"
                size={"medium"}
                id="max"
                label="To"
                variant="standard"
                defaultValue={"MAX"}
                inputProps={{ readOnly: true }}
              ></CssTextField>
            ) : (
              <CssTextField
                type="text"
                size={"medium"}
                id="outlined-basic"
                label="To"
                variant="standard"
                value={zones[zone] - 1}
                onChange={(e) => setZoneTo(zone, e)}
              />
            )}
          </Item>
        </Box>
      </>
    );
  }

  return (
    <Container>
      {/* <h3>HR ZONES</h3> */}
      <Box
        sx={{
          rowGap: 1,
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          paddingBottom: "1rem",
        }}
      >
        <Item>
          <CssTextField
            type="text"
            size={"medium"}
            id="outlined-basic"
            label="Age"
            variant="standard"
            value={age ?? ""}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              const { value } = e.target;
              if (isOnlyNumbers(value)) {
                let num = Number.parseInt(value);
                setMaxHr(220 - num);
                setAge(num);
              }
            }}
          />
        </Item>
        <Item>
          <CssTextField
            type="text"
            size={"medium"}
            id="outlined-basic"
            label="Max HR"
            variant="standard"
            value={maxHr}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              const { value } = e.target;
              if (isOnlyNumbers(value)) {
                let num = Number.parseInt(value);
                if (!isNaN(num)) {
                  setMaxHr(num);
                }
              }
            }}
          />
        </Item>
        <Item>
          <CssTextField
            type="text"
            size={"medium"}
            id="outlined-basic"
            label="Resting HR"
            variant="standard"
            value={restingHr ?? ""}
            onChange={setRestingHrInput}
          />
        </Item>
        <Item marginTop="auto" marginBottom="auto">
          <Button
            variant="contained"
            disabled={
              isNaN(maxHr) ||
              restingHr == null ||
              restingHr <= 0 ||
              isNaN(restingHr!)
            }
            onClick={calculateZones}
          >
            Calculate
          </Button>
        </Item>
      </Box>

      <Box
        sx={{
          rowGap: 1,
          display: "grid",
          paddingBottom: "1rem",
        }}
      >
        {zoneRow(1, zoneColors[0])}
        {zoneRow(2, zoneColors[1])}
        {zoneRow(3, zoneColors[2])}
        {zoneRow(4, zoneColors[3])}
        {zoneRow(5, zoneColors[4])}
      </Box>
      <Box
        sx={{
          rowGap: 1,
          display: "grid",
          alignItems: "center",
        }}
      >
        <Item sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            disabled={zones.filter((v) => v > 0).length != 5}
            onClick={update}
          >
            Update
          </Button>
          <Snackbar
            open={updateStatus == UpdateStatus.success}
            autoHideDuration={6000}
            onClose={() => setUpdateStatus(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              HR data successfully updated.
            </Alert>
          </Snackbar>
          <Snackbar
            open={updateStatus == UpdateStatus.error}
            autoHideDuration={6000}
            onClose={() => setUpdateStatus(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred attempting to update HR data.
            </Alert>
          </Snackbar>
        </Item>
      </Box>
    </Container>
  );
}

export default HrZones;
